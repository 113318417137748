import React from "react";

import './blogs.css';

import {
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBCardImage,
    MDBBtn,
    MDBRipple
} from 'mdb-react-ui-kit';
import economyImg from './MAX_9296-1024x683-1-1024x600.jpg';
import partnerImg from './technology in real estate (13).png';
import samanaImg from './Samana_1900676d1a3_medium.webp';
import { MdKeyboardArrowRight } from "react-icons/md";


const Blogs = () => {

    return (
        <>
            <section id="blogs_section">
                <h5 id="blog_sbhdng">Insights To Empower You</h5>
                <h1 id="blog_hdng">
                    Read Our Blogs
                </h1>

                <div id="blog_cards_container">

                    <MDBCard className="blogs_card">
                        <MDBRipple id="blog_card_img" rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
                            <MDBCardImage style={{ height: '100 %', width: '100%' }} src={samanaImg} fluid alt='...' />
                        </MDBRipple>
                        <MDBCardBody className="blog_card_txt_div">
                            <MDBCardTitle className="blog_card_hdng">Digital Transformation In MENA Real Estate Landscape</MDBCardTitle>
                            <MDBCardText className="blog_card_txt">
                                As digital transformation reshapes industries globally, the real estate sector in the MENA region stands at a pivotal juncture.
                            </MDBCardText>
                            <MDBBtn
                                onClick={() => window.location.href = 'https://blog.menaproptech.com/2024/07/16/digital-transformation-in-mena-real-estate/'}
                                id="blog_card_btn">Read More <MdKeyboardArrowRight style={{ fontSize: '17px', marginLeft: '5px' }} /></MDBBtn>
                        </MDBCardBody>
                    </MDBCard>

                    <MDBCard className="blogs_card">
                        <MDBRipple id="blog_card_img" rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
                            <MDBCardImage style={{ height: '100 %', width: '100%' }} src={partnerImg} fluid alt='...' />
                        </MDBRipple>
                        <MDBCardBody className="blog_card_txt_div">
                            <MDBCardTitle className="blog_card_hdng">International Real Estate Partnerships Drive Innovation</MDBCardTitle>
                            <MDBCardText className="blog_card_txt">
                                a recent strategic partnership between UAE’s Reportage Properties and Lahore’s Empire Holding Pakistan. MENA Proptech underscores how international collaboration is setting new benchmarks in the property market.
                            </MDBCardText>
                            <MDBBtn
                                onClick={() => window.location.href = 'https://blog.menaproptech.com/2024/07/16/international-real-estate-partnerships/'}
                                id="blog_card_btn">Read More <MdKeyboardArrowRight style={{ fontSize: '17px', marginLeft: '5px' }} /></MDBBtn>
                        </MDBCardBody>
                    </MDBCard>

                    <MDBCard className="blogs_card">
                        <MDBRipple id="blog_card_img" rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
                            <MDBCardImage style={{ height: '100%', width: '100%' }} src={economyImg} fluid alt='...' />
                        </MDBRipple>
                        <MDBCardBody className="blog_card_txt_div">
                            <MDBCardTitle className="blog_card_hdng">UAE-Pakistan Economic Relations</MDBCardTitle>
                            <MDBCardText className="blog_card_txt">
                                Mr. Bakheet Ateeq Al-Rumeithi, the Consul General of UAE, shared his vision on the burgeoning economic ties between the UAE and Pakistan.
                            </MDBCardText>
                            <MDBBtn
                                onClick={() => window.location.href = 'https://blog.menaproptech.com/2024/06/26/pak-uae-economic-relationship/'}
                                id="blog_card_btn">Read More <MdKeyboardArrowRight style={{ fontSize: '17px', marginLeft: '5px' }} /></MDBBtn>
                        </MDBCardBody>
                    </MDBCard>

                </div>

                {/* <button
                    onClick={() => window.location.href = 'https://blog.landtrack.pk/'}
                    id="blog_btn">Read All Blogs <MdKeyboardArrowRight />
                </button> */}

            </section>
        </>
    );
};

export default Blogs;