import React, { useState, useEffect } from 'react';
import './form.css';  // Ensure the CSS path is correct

import {
    MDBInput,
    MDBCol,
    MDBRow,
    MDBCheckbox,
    MDBBtn,
    MDBIcon
} from 'mdb-react-ui-kit';

const Form = () => {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [message, setMessage] = useState("");


    const handleSubmit = async (event) => {
        event.preventDefault();

        // Basic validation (example)
        if (!email || !firstName || !contactNumber) {
            alert('Please fill in all required fields.');
            return;
        }

        const payload = {
            "userName": "atif@landtrack.pk",
            "fingerPrint": "UPWdLuPQYsjigBtZXMzAqIc2WHsWuu5aRbTy0SglSNM48phtIkHxNKfLxdHDB3fnxJQFr4jFpNsc6h4qj8NJnHMH5IBWLckZsiGz",
            "sendWelcomeLetter": 'true',
            "title": firstName + '' + lastName,
            "mobile": contactNumber,
            "personalMobileCountryCode": "+92",
            "whatsappCountryCode": "+92",
            "whatsapp": contactNumber,
            "tags": "string",
            "linkedIn": "string",
            "dealSize": "string",
            "platformID": "462",
            "statusID": "New Lead",
            "date": "string",
            "message": message,
            "users": "atif@landtrack.pk",
            "companyName": "string",
            "position": "string",
            "email": email
        };

        try {
            const response = await fetch('https://app.fortify.biz/api/fortifyv2/SetLead', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });

            // if (!response.ok) {
            //     const errorData = await response.json();
            //     throw new Error(errorData.error || 'Failed to submit form');
            // }

            const result = await response.json();
            console.log(result);
            alert('Form submitted successfully!');
            setFirstName('');
            setLastName('');
            setEmail('');
            setContactNumber('');
            setMessage("");

            console.log(payload);

        } catch (error) {
            console.error('Submission error:', error);
            alert(error.message);

            console.log(payload);
        }
    };

    return (

        <>

            {/* <iframe src="https://landtrack.pk" style={{ width: '100%', height: '', border: 'none' }}></iframe> */}
            <section id='form_section'>
                <div id='form_txt_div'>
                    <h5 id='form_sbhdng'>Ready to Supercharge Your Real Estate Efforts?</h5>
                    <h1 id='form_hdng'>Get In Touch Today!</h1>

                    <p id='form_txt'>Have questions, concerns, or ready to start your real estate journey? Reach out to us today for personalized assistance and expert guidance.</p>
                </div>

                <div id='form_div'>

                    <form id='form' onSubmit={handleSubmit}>
                        <MDBRow className='mb-4'>
                            <MDBCol id='field'>
                                <MDBInput
                                    value={firstName}
                                    onChange={e => setFirstName(e.target.value)} id='first_name_input'
                                    label='First Name'
                                />
                            </MDBCol>
                            <MDBCol>
                                <MDBInput value={lastName} onChange={e => setLastName(e.target.value)} id='last_name_input' label='Last name' />
                            </MDBCol>
                        </MDBRow>
                        <MDBInput value={email} onChange={e => setEmail(e.target.value)} className='mb-4' type='email' id='email_input' label='Email address' />
                        <MDBInput value={contactNumber} onChange={e => setContactNumber(e.target.value)} className='mb-4' type='number' id='phone_input' label='Contact No.' />
                        <MDBInput value={message} onChange={e => setMessage(e.target.value)} className='mb-4' type='text' id='msg_input' label='Your Message' />

                        <MDBBtn type='submit' className='mb-4 submit_btn' block
                            id='form_btn'
                        >Submit</MDBBtn>
                    </form>

                </div>
            </section>

        </>

    );
};

export default Form;