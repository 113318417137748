import React, { useState } from "react";
import { Link } from "react-router-dom";

import './services.css';

// import Typical from 'react-typical';
import RotatingText from "../../Components/RotateText/RotateText";
import { MdKeyboardArrowRight } from "react-icons/md";
import dataDash from './data dashboard.png';
import marketingDash from './marketing dashboard.png';
import fortifyDash from './fortify dash.png';
import vrImage from './al knowledge (5).png';
import listingDash from './listing.png';
import datastateLogo from './logo1.png';
import fortifyLogo from './logo1 (1).png';
import lawtrackLogo from './lawtrack-220x54.png';
import realtorsLogo from './realtorstrack.pk.png';

const Services = () => {

    const [b2bClicked, setB2bClicked] = useState(true);
    const [b2cClicked, setB2cClicked] = useState(false);

    const sectionScrollHandler = (sectionId) => {
        const section = document.getElementById(sectionId);

        if (section) {
            window.scrollTo({
                top: section.offsetTop,
                behavior: "smooth"
            });
        };
    };

    const checkResponsive = () => {
        if (window.innerWidth < 450) {
            return true;
        }
        else if (window.innerWidth > 450) {
            return false;
        };
    };


    return (
        <>
            <section id="service_section">
                <h1 id="service_hdng">Our Services</h1>
                <p id="service_txt">We Support You Throughout Your Real Estate Journey</p>


                <div id="switch_btn_div">
                    <button id={b2bClicked ? 'clicked' : 'switch_btn'}
                        onClick={() => { setB2bClicked(true); setB2cClicked(false); }}>
                        B2B
                    </button>
                    <button id={b2cClicked ? 'clicked' : 'switch_btn'}
                        onClick={() => { setB2cClicked(true); setB2bClicked(false); }}>
                        B2C
                    </button>
                </div>

                {b2bClicked

                    ?

                    (<div id="services_div"
                        onDragEnd={() => { setB2cClicked(true); setB2bClicked(false); }}>

                        {/* fortify */}
                        <div className="service_item_div">
                            <div className="service_item_txt_div">
                                <h3 className="service_item_hdng">
                                    Real Estate Management Software
                                </h3>
                                <span
                                    id="powered_by_txt">powered by <img id="powered_by_img" src={fortifyLogo} alt="" style={{ width: '45px' }} />
                                </span>
                                <p className="service_item_txt">
                                    Offer comprehensive software solutions tailored for real estate agencies, including property management, listings management, and CRM tools.
                                    {/* <RotatingText
                                        texts={[
                                            'Manage customer relationships and business administration with comprehensive CRM tools.',
                                            'Leverage WhatsApp and social media integrations for seamless communication and lead management.',
                                            'Efficiently handle client documents, campaign management, and maintain a structured contact book.'
                                        ]}
                                        interval={3000}
                                    /> */}
                                </p>
                                <button
                                    className="service_item_btn" style={{ backgroundColor: '#0C9780' }}>
                                    <Link id="link" target='_blank' to='https://fortify.landtrack.pk/'>
                                        Explore Fortify <MdKeyboardArrowRight />
                                    </Link>
                                </button>
                            </div>
                            <div className="service_item_img_div">
                                <img className="service_item_img" src={fortifyDash} alt="" />
                            </div>
                        </div>

                        {/* ar/vr */}
                        <div className="service_item_div" style={{
                            flexDirection: checkResponsive() ? 'column' : 'row'
                        }}>
                            <div className="service_item_img_div">
                                <img className="service_item_img" src={vrImage} alt="" />
                            </div>
                            <div className="service_item_txt_div">
                                <h3 className="service_item_hdng">
                                    Virtual Tours and 3D Visualization
                                </h3>
                                <p className="service_item_txt">
                                    Showcase advanced visualization tools for property tours and interactive 3D floor plans.
                                </p>
                                <button
                                    className="service_item_btn" style={{ backgroundColor: '#F11927' }}>
                                    <Link id="link" target='_blank' to='https://proptech-services-page.netlify.app/'>
                                        Explore More <MdKeyboardArrowRight />
                                    </Link>
                                </button>
                            </div>
                        </div>

                        {/* datastate */}
                        <div className="service_item_div">
                            <div className="service_item_txt_div">
                                <h3 className="service_item_hdng">
                                    Data Analytics and Feasibility Studies
                                </h3>
                                <span id="powered_by_txt">powered by <img id="powered_by_img" src={datastateLogo} alt="" /></span>
                                <p className="service_item_txt">
                                    Provide data-driven insights and analytics to optimize marketing strategies, pricing decisions, and property investments.
                                </p>
                                <button
                                    className="service_item_btn" style={{ backgroundColor: '#3E239D' }}>
                                    <Link id="link" target='_blank' to='https://www.datastate.ai/'>
                                        Explore DataState.ai <MdKeyboardArrowRight />
                                    </Link>
                                </button>
                            </div>
                            <div className="service_item_img_div">
                                <img className="service_item_img" src={dataDash} alt="" />
                            </div>
                        </div>

                        {/* marketing */}
                        <div className="service_item_div" style={{
                            flexDirection: checkResponsive() ? 'column' : 'row'
                        }}>
                            <div className="service_item_img_div">
                                <img className="service_item_img" src={marketingDash} alt="" />
                            </div>
                            <div className="service_item_txt_div">
                                <h3 className="service_item_hdng">
                                    Developer Sector Solutions
                                </h3>
                                <p className="service_item_txt">
                                    Customized software development services for developers, such as project management software, collaboration tools, and data visualization platforms.
                                </p>
                                <button
                                    className="service_item_btn" style={{ backgroundColor: '#F11927' }}>
                                    <Link id="link" target='_blank' to='https://proptech-services-page.netlify.app/'>
                                        Explore More <MdKeyboardArrowRight />
                                    </Link>
                                </button>
                            </div>
                        </div>

                        {/* listing */}
                        <div className="service_item_div" style={{ margin: '0', padding: '0 40px' }}>
                            <div className="service_item_txt_div">
                                <h3 className="service_item_hdng">
                                    Property Listing Platform
                                </h3>

                                <p className="service_item_txt">
                                    We develop platforms designed for seamless property listing, enabling real estate agents to efficiently manage their portfolios.
                                </p>
                                <button
                                    className="service_item_btn" style={{ backgroundColor: '#F11927' }}>
                                    <Link id="link" target='_blank' to='https://proptech-services-page.netlify.app/'>
                                        Explore More <MdKeyboardArrowRight />
                                    </Link>
                                </button>

                            </div>
                            <div className="service_item_img_div">
                                <img className="service_item_img" src={listingDash} alt="" />
                            </div>
                        </div>

                    </div>)

                    :

                    (<div id="services_div"
                        onDragEnd={() => { setB2bClicked(true); setB2cClicked(false); }}>

                        {/* datastate */}
                        <div className="service_item_div">
                            <div className="service_item_txt_div">
                                <h3 className="service_item_hdng">
                                    Data Analytics and Insights for Informed Decisioncs
                                </h3>
                                <span id="powered_by_txt">powered by <img id="powered_by_img" src={datastateLogo} alt="" /></span>
                                <p className="service_item_txt">
                                    <RotatingText
                                        texts={[
                                            'Detailed property market trends and pricing analysis to guide your investments.',
                                            'Predictive analytics to forecast property value changes and market conditions.',
                                            'Customised reports on neighbourhood demographics, crime rates, and school ratings.'
                                        ]}
                                        interval={3000}
                                    />
                                </p>
                                <button
                                    className="service_item_btn" style={{ backgroundColor: '#3E239D' }}>
                                    <Link id="link" target='_blank' to='https://www.datastate.ai/'>
                                        Explore DataState.ai <MdKeyboardArrowRight />
                                    </Link>
                                </button>
                            </div>
                            <div className="service_item_img_div">
                                <img className="service_item_img" src={dataDash} alt="" />
                            </div>
                        </div>

                    </div>)
                }


            </section>
        </>
    );
};

export default Services;