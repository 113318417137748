import React from "react";

import './about.css';

import about_img from './27175321_i9uq_jks3_220416.jpg';
import { FaCheckCircle } from "react-icons/fa";


const About = () => {

    return (
        <>

            <section id="about_section">

                <div id="about_img_div">
                    <img id="about_img" src={about_img} alt="" />
                </div>

                <div id="about_txt_div">
                    <h1 id="about_hdng">About Us</h1>

                    <p id="about_txt">
                        We are MENA Proptech, committed to delivering exceptional service and expertise in every real estate transaction. With a dedicated team of professionals, we prioritize your needs and goals, ensuring a seamless and rewarding experience. Whether you're buying, selling, or investing, trust us to guide you through the process with integrity and excellence.
                    </p>
                    {/* <span className="points">
                        <FaCheckCircle style={{ color: '#EF4723', fontSize: '17px', marginRight: '7px' }} /> We utilize modern technology and AI for transparency.
                    </span>
                    <span className="points">
                        <FaCheckCircle style={{ color: '#EF4723', fontSize: '17px', marginRight: '7px' }} /> We leverage data insights for informed decison making.
                    </span>
                    <span className="points">
                        <FaCheckCircle style={{ color: '#EF4723', fontSize: '17px', marginRight: '7px' }} /> We Empower Home-owners & buyers with due diligence.
                    </span>
                    <span className="points">
                        <FaCheckCircle style={{ color: '#EF4723', fontSize: '17px', marginRight: '7px' }} /> Supporting you from your first investment to a perfect home.
                    </span> */}
                </div>

            </section>

        </>
    );
};

export default About;